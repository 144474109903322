import React from 'react';
import {  Route, Routes, Outlet  } from 'react-router-dom';

import ScrollToTop from './../layouts/ScrollToTop';
import Header2 from './../layouts/Header2';
import Footer from './../layouts/Footer';
import Home from './Home';
import Home2 from './Home2';
import Home3 from './Home3';
import AboutUs from './AboutUs';
import Volunteer from './Volunteer';
import BecomeVolunteer from './BecomeVolunteer';
import Faq from './Faq';
import Certificates from './Certificates';
import AskQuestion from './AskQuestion';
import HappyClients from './HappyClients';
import HowItWorks from './Donate';
import Mission from './Mission';
import TermsCondition from './TermsCondition';
import Error from './Error';
import UnderMaintenance from './UnderMaintenance';
import ComingSoon from './ComingSoon';
import BrowseFundraiser from './BrowseFundraiser';
import BecomeFundraiser from './BecomeFundraiser';
import FundraiserDetail from './FundraiserDetail';
//
import Project from './Project';
import ProjectCategories from './ProjectCategories';
import ProjectSidebar from './ProjectSidebar';
import ProjectStory from './ProjectStory';
//
import Blog from './Blog';
import BlogGrid from './BlogGrid';
import BlogList from './BlogList';
import BlogDetails from './BlogDetails';
//
import ContactUs from './ContactUs';
import GalleryPage from './GalleryPage';
//
import News from './News';
import NewsDetails from './NewsDetails'
import Partners from './Partners';
import Donate from './Donate';


function Index(){
	return(
		<>
			<Routes>
				<Route path='*' exact element={<ComingSoon />} />
				<Route path='/' exact element={<Home2 />} />
				
				<Route  element={<MainLayout />} > 
					<Route path='/about-us' exact element={<AboutUs />} />

					<Route path='/volunteer' exact element={<Volunteer/>} />
					
					<Route path='/testimonials' exact element={<HappyClients />} />

					<Route path='/donate' exact element={<Donate />} />
					
					<Route path='/browse-fundraiser' exact element={<BrowseFundraiser />} />
					
					<Route path='/project/:slug' exact element={<FundraiserDetail />} />
					<Route path='/project' exact element={<Project />} />
					

					<Route path='/partners' exact element={<Partners/>}/>

					<Route path='/news' exact element={<News/>}/>
					<Route path='/news/:slug' exact element={<NewsDetails />} />

					<Route path='/blog' exact element={<BlogGrid />} />
					<Route path='/blog/:slug' exact element={<BlogDetails />} />

					<Route path='/contact-us' exact element={<ContactUs />} />

					<Route path='/gallery' exact element={<GalleryPage />} />
				</Route>
			</Routes>	
			<ScrollToTop />
		</>
		 		
	)
} 

function MainLayout(){
	
	return (
		<div className="page-wraper">
			<Header2 />
			<Outlet /> 
			<Footer />
		</div>
	)
  
  };
export default Index;