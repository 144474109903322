import React,{useState,useEffect} from 'react';
import {Link,useParams} from 'react-router-dom';
import { format } from "date-fns"
import { PortableText } from '@portabletext/react';
import { client,urlFor } from '../lib/sanity';

//import pic1 from '../assets/images/blog/recent-blog/pic1.jpg';
//import pic2 from '../assets/images/blog/recent-blog/pic2.jpg';
//import pic3 from '../assets/images/blog/recent-blog/pic3.jpg';
//import pic4 from '../assets/images/blog/recent-blog/pic4.jpg';
import NewsSlider from '../components/Home/NewsSlider';
import UpdateBlog from '../components/Home/UpdateBlog';
import BlogDetailsLeftBar from '../components/BlogDetailsLeftBar';
import { IMAGES } from '../constant/theme';

const widgetBlog = [
    {title:"New vaccine for cattle calf loss learned.", image:IMAGES.Recentblog1},
    {title:"He Created the Web. Now He’s Out to Remake.", image:IMAGES.Recentblog2},
    {title:"Partnering to create a community.", image:IMAGES.Recentblog3},
    {title:"Best & Less published their supplier lists.", image:IMAGES.Recentblog4},
];

const tagData = [
    { title:"Business"},
    { title:"Corporate"},
    { title:"Blog"},
    { title:"Marketing"},
    { title:"Creative"},
    { title:"Web"},
    { title:"Workers"},
    { title:"Modern"},
];

const NewsDetails = () => {
    const {slug} = useParams()

    //News
    const [post,setPosts] = useState()
    const [recent,setRecent] = useState([])

    useEffect(() => {
		client
        .fetch(
            `*[_type == "news" && slug.current == '${slug}'][0]{
                _id,
                title,
                publishedAt,
                body,
                category->{
                  title
                },
                mainImage{
                  asset->{
                    _id,
                    url
                  },
                },
                author->{
                    name,
                    bio,
                    image{
                      asset->{
                        _id,
                        url
                      }
                    }
                },
                slug
              }`
        )
        .then((data) => setPosts(data))
        .catch(console.error);
	}, [slug]);

    useEffect(() => {
		client
        .fetch(
            `*[_type == "news" && slug.current != '${slug}']{
                _id,
                title,
                publishedAt,
                mainImage{
                  asset->{
                    _id,
                    url
                  },
                },
                slug,
              }| order(publishedAt desc)`
        )
        .then((data) => setRecent(data))
        .catch(console.error);
	}, [slug]);

    //Recent posts
   
    
    return (
        <>
            <div className="page-content bg-white">
                <section className="content-inner-2">
                    <div className="container">			
                        <div className="row ">
                            <div className="col-xl-8 col-lg-8 m-b30">
                                {post && (
                                    <>
                                        <div className="dz-card blog-single sidebar">
                                            <div className="dz-media">
                                                <img src={urlFor(post.mainImage).url()} alt="" />
                                            </div>
                                            <div className="dz-info">
                                                <div className="dz-meta">
                                                    
                                                    <ul>
                                                        <li className="post-date"><i className="fa-solid fa-calendar-days"></i>{format(new Date(post.publishedAt), "dd MMMM yyyy")}</li>
                                                
                                                        <li className="post-author"><Link to={"#"}><i className="fa-solid fa-user"></i> By {post.author.name}</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="dz-post-text">
                                                    <h1 className="dz-title">{post.title}</h1>
                                                    <PortableText value={post.body}/>
                                                </div>
                                            </div>
                                            <div className="dz-share-post">
                                                <div className="dz-social-icon">
                                                    <h6 className="title">Share:</h6>
                                                    <ul>
                                                        <li><Link to={"#"} className="fab fa-facebook-f"></Link></li>
                                                        <li><Link to={"#"} className="fab fa-instagram"></Link></li>
                                                        <li><Link to={"#"} className="fab fa-twitter"></Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="author-box blog-user m-b60">
                                            <div className="author-profile-info">
                                                <div className="author-profile-pic">
                                                    {post.author.image && (<img src={urlFor(post.author.image).url()} alt={post.author.name} />)}
                                                </div>
                                                <div className="author-profile-content">
                                                    <h4>{post.author.name}</h4>
                                                    <PortableText value={post.author.bio}/>
                                                    {/*<ul className="list-inline m-b0">
                                                        <li><a href="https://www.facebook.com" target="_blank"  rel="noreferrer"  className="btn-link"><i className="fab fa-facebook-f"></i></a></li>
                                                        <li><a href="https://www.twitter.com" target="_blank"  rel="noreferrer"  className="btn-link"><i className="fab fa-twitter"></i></a></li>
                                                        <li><a href="https://www.google.com" target="_blank"  rel="noreferrer"  className="btn-link"><i className="fab fa-google-plus"></i></a></li>
                                                        <li><a href="https://www.youtube.com" target="_blank"  rel="noreferrer"  className="btn-link"><i className="fab fa-youtube"></i></a></li>
            </ul>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <aside className="side-bar sticky-top">
                                    <div className="widget style-1 recent-posts-entry">
                                        <div className="widget-title">
                                            <h5 className="title">Recent Post</h5>
                                        </div>
                                        <div className="widget-post-bx">
                                            {recent && recent.map((item, ind)=>(
                                                <div className="widget-post clearfix" key={ind}>
                                                    <div className="dz-media"> 
                                                        <img src={urlFor(item.mainImage).url()} alt="Image"/>
                                                    </div>
                                                    <div className="dz-info">
                                                        <h6 className="title"><Link to={"/news/"+item.slug.current}>{item.title}</Link></h6>
                                                        <div className="dz-meta">
                                                            <ul>
                                                                <li className="post-date"><i className="fa-solid fa-calendar-days"></i>{format(new Date(item.publishedAt), "dd MMMM yyyy")}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                              
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<section className="content-inner-2">
                    <div className="container">
                        <div className="section-head">
                            <h2 className="title">Related Articles</h2>
                        </div>
                        <NewsSlider />
                    </div>
                </section>*/}
                <div className="call-action style-1 content-inner-1">
				    <div className="container">
                        <UpdateBlog />
                    </div>
                </div>
            </div>
           
        </>
    );
};

export default NewsDetails;