import React,{useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {PortableText} from '@portabletext/react'

import { client,urlFor } from '../lib/sanity';
import PageBanner from '../layouts/PageBanner';
import {CommentBlog} from '../components/BlogDetailsLeftBar';

import bg from '../assets/images/banner/bnr4.jpg';
import avat1 from '../assets/images/avatar/avatar1.jpg';
import avat2 from '../assets/images/avatar/avatar2.jpg';
import avat3 from '../assets/images/avatar/avatar3.jpg';
import avat4 from '../assets/images/avatar/avatar4.jpg';
import avat5 from '../assets/images/avatar/avatar5.jpg';
import blog1 from '../assets/images/blog/recent-blog/pic1.jpg';
import blog2 from '../assets/images/blog/recent-blog/pic2.jpg';
import UpdateBlog from '../components/Home/UpdateBlog';
import GallerySlider2 from '../components/Fundraiser/GallerySlider2';




const postBlog = [
    {image:blog1, title:"How To Teach Education Like A Pro."},
    {image:blog2, title:"Quick and Easy Fix For Your Education."},
];
const teamBlog =[
    {title:"Jake Johnson", image:avat1},
    {title:"Celesto Anderson", image:avat2},
    {title:"John Doe", image:avat3},
    {title:"Jake Johnson", image:avat4}
];

const donorsBlog = [
    {title:"Celesto Anderson", image:avat5, price:"$ 1,812"},
    {title:"John Doe", image:avat4, price:"$ 1,564"},
    {title:"Celesto Anderson", image:avat3, price:"$ 1,225"},
    {title:"Jake Johnson", image:avat2, price:"$ 9,00"},
];



const FundraiserDetail = () => {
    const {slug} = useParams()
    const [modalDonate, setModalDonate] = useState(false);
    const [referModal, setReferModal] = useState(false);

    //Project
    const [post,setPosts] = useState()

    const [projects,setProjects] = useState()
    useEffect(() => {
		client
        .fetch(
            `*[_type == "project" && slug.current == '${slug}'][0]{
                _id,
                title,
                body,
                gallery[] {
                  asset->{
                    _id,
                    url
                  },
                },
                mainImage{
                  asset->{
                    _id,
                    url
                  },
                },
            }`
        )
        .then((data) => setPosts(data))
        .catch(console.error);
	}, [slug]);

    useEffect(() => {
		client
        .fetch(
            `*[_type == "project" && slug.current != '${slug}']{
                _id,
                title,
                mainImage{
                  asset->{
                    _id,
                    url
                  },
                },
            }`
        )
        .then((data) => setProjects(data))
        .catch(console.error);
	}, [slug]);

    return (
        <>
            <div className="page-content bg-white">
                <PageBanner maintitle="Fundraiser" pagetitle={post && post.title} background={"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698591607/erri-website/396867842_240400009039572_873250941821001763_n_ww1j8g.jpg"}/>
                <section className="content-inner-2">
                    <div className="container">	
                        <div className="row">
                            <div className="col-xl-8 col-lg-8 m-b30">
						        <div className="fundraiser-single">
                                    <div className="swiper fundraiser-gallery-wrapper">
                                        <GallerySlider2 images={post && post.gallery} />
                                    </div>
                                    <h2 className="title">{post && post.title}</h2>
                                    <PortableText
                                        value={post && post.body}
                                    />
                                    
                                    <ul className="fundraiser-bottom">
                                        <li>
                                            <Link to={"#"} className="btn btn-primary btn-sm" onClick={()=>setModalDonate(true)} data-bs-target="#modalDonate">
                                                <i className="flaticon-like me-2"></i> Donate Now 
                                            </Link>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/login/" target="_blank" rel="noreferrer" className="btn facebook btn-primary btn-sm">
                                                <i className="fa-brands fa-facebook-square me-2"></i> Spread The World
                                            </a>
                                        </li>
                                        <li><a href="https://web.whatsapp.com/" target="_blank" rel="noreferrer" className="btn whatsapp btn-primary btn-sm"><i className="fa-brands fa-whatsapp me-2"></i>Share</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <aside className="side-bar sticky-top">
                                    <div className="widget style-1 widget_donate">
                                        <Link to={"#"} className="btn btn-donate btn-primary w-100"  data-bs-toggle="modal" data-bs-target="#modalDonate"><i className="flaticon-like me-3"></i> Donate Now </Link>
                                    </div>
                                        
                                    {/* <!-- Projects --> */}
                                    <div className="widget style-1 recent-posts-entry">
                                        <div className="widget-title">
                                            <h5 className="title">Projects</h5>
                                        </div>	
                                        <div className="widget-post-bx">
                                            {projects && projects.map((data)=>(
                                                <div className="widget-post clearfix" key={data._id}>
                                                    <div className="dz-media"> 
                                                        <img src={urlFor(data.mainImage).url()} alt="" />
                                                    </div>
                                                    <div className="dz-info">
                                                        <h6 className="title"><Link to={"/blog-details"}>{data.title}</Link></h6>
                                                        <div className="dz-meta">
                                                            <ul>
                                                                <li className="post-date"><i className="flaticon-placeholder"></i></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>                
                                </aside>
                            </div>            
                        </div>
                    </div>
                </section>
                <div className="call-action style-1 content-inner-1">
			        <div className="container">
                        <UpdateBlog />
                    </div>
                </div>
            </div>  
            <Modal className="modal fade modal-wrapper" id="modalRefer" show={referModal} onHide={setReferModal} centered>               
                <div className="modal-header">
                    <h5 className="modal-title">Refer a Friend</h5>
                    <button type="button" className="btn-close" onClick={()=>setReferModal(false)}><i className="flaticon-close"></i></button>
                </div>
                <div className="modal-body">
                    <form className="dz-form contact-bx" method="POST">
                        <div className="form-group style-1 align-items-center">
                            <label className="form-label">Name</label>
                            <div className="input-group">
                                <input name="dzName" required="" type="text" className="form-control" placeholder="Jakob Bothman" />
                            </div>
                        </div>
                        <div className="form-group style-1 align-items-center">
                            <label className="form-label">Email address</label>
                            <div className="input-group">
                                <input name="dzEmail" required="" type="text" className="form-control" placeholder="marseloque@mail.com" />
                            </div>
                        </div>
                        <div className="form-group style-1 align-items-center">
                            <label className="form-label">Phone Number</label>
                            <div className="input-group">
                                <input name="dzPhoneNumber" type="number" className="form-control" placeholder="Phone Number" />
                            </div>
                        </div>
                        <div className="form-group mb-0 text-center">
                            <button name="submit" type="submit" value="Submit" className="btn btn-primary">Refer Now</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal className="modal fade modal-wrapper" id="modalDonate" show={modalDonate} onHide={setModalDonate} > 
                <div className="modal-header">
                    <h5 className="modal-title">Choose a donation amount</h5>
                    <button type="button" className="btn-close" onClick={()=>setModalDonate(false)}><i className="flaticon-close"></i></button>
                </div>
                <div className="modal-body">
                    <form action="index.html">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tag-donate style-1">
                                    <div className="donate-categories">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                $500
                                            </label>
                                        </div>
                                    </div>
                                    <div className="donate-categories">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                $1000
                                            </label>
                                        </div>
                                    </div>
                                    <div className="donate-categories">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" defaultChecked />
                                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                $2000
                                            </label>
                                        </div>
                                    </div>
                                    <div className="donate-categories">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" defaultChecked />
                                            <label className="form-check-label" htmlFor="flexRadioDefault4">
                                                $5000
                                            </label>
                                        </div>
                                    </div>
                                    <p>Most Donors donate approx <span>$1000</span> to this Fundraiser</p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form-label">Other Amount</label>
                                    <div className="input-group">
                                        <input type="number" className="form-control"  placeholder="Other Amount" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form-label">Name</label>
                                    <div className="input-group">
                                        <input name="dzName" required="" type="text" className="form-control" placeholder="Jakob Bothman" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form-label">Email address</label>
                                    <div className="input-group">
                                        <input name="dzEmail" required type="text" className="form-control" placeholder="info@mail.com" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form-label">Phone Number</label>
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Phone Number" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form-label">Address</label>
                                    <div className="input-group">
                                        <input required type="text" className="form-control" placeholder="Address" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form-label">Pancard</label>
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Pancard" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group mb-0 text-center">
                                    <button name="submit" type="submit" value="Submit" className="btn btn-primary btn-block">Proceed To Pay</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>                    
            </Modal>
        </>
    )
}

export default FundraiserDetail;