import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
//import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper styles
import "swiper/css";

//Images
import logo1 from './../../assets/images/clients-logo/logo1.png';
import logo2 from './../../assets/images/clients-logo/logo2.png';
import logo3 from './../../assets/images/clients-logo/logo3.png';
import logo4 from './../../assets/images/clients-logo/logo4.png';
import logo5 from './../../assets/images/clients-logo/logo5.png';




// import Swiper core and required modules
import { Autoplay } from "swiper";

//SwiperCore.use([EffectCoverflow,Pagination]);

const dataBlog = [
	{image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171506/erri-website/logos/WhatsApp_Image_2023-07-12_at_113719_AM-removebg-preview-photoaidcom-cropped-1_mwptoq.png" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171494/erri-website/logos/zxicqG91_400x400_r4y2x1.jpg" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171493/erri-website/logos/Juba_City_Council_logo_d5vvuj.png" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171489/erri-website/logos/white-logo1589563336_ceobng.png" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171489/erri-website/logos/sp-white-logo_gzkixy.png" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171488/erri-website/logos/278267351_101146889244613_6676756577822866036_n_loojou.jpg" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171487/erri-website/logos/south-sudan-logo_bxmfar.svg" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171484/erri-website/logos/m-gurush-logo-1_ikceba.svg" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171483/erri-website/logos/mtn-logo_jtfflg.svg" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171484/erri-website/logos/311521877_500288912112449_9178651543365580328_n_smk5ph.jpg"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171483/erri-website/logos/logonew_1_k7feit.png"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171482/erri-website/logos/logo-1-1_bcqgge.png"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171480/erri-website/logos/logo_fhtzsv.svg"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171480/erri-website/logos/LOGO_j83gnf.webp"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171479/erri-website/logos/logo_1_bqfpqm.png"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171477/erri-website/logos/images_tjsanf.png"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171477/erri-website/logos/Bank_of_South_Sudan_logo_tzfezz.png"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171474/erri-website/logos/Equity_Group_Logo_rp5shx.png"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171474/erri-website/logos/images_zbr6rs.jpg"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171474/erri-website/logos/Icon-Square_nqq1ao.webp"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171471/erri-website/logos/Emblem_of_the_National_Legislative_Assembly_of_South_Sudan_slow9e.jpg"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171470/erri-website/logos/Emblem_of_the_Abyei_Area_gnv2cz.png"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171469/erri-website/logos/273846964_666901697992331_6834030223941458779_n_eiwjfb.jpg"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171468/erri-website/logos/download_gtrufm.jpg"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171465/erri-website/logos/download_1_ouww4p.jpg"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171463/erri-website/logos/328245903_722501519278199_2446965178820805639_n_tiuctp.webp"},
];

const PartnershipSlider = () => {
    return (
        <>
             
            <Swiper className="clients-swiper"
				speed= {1500}
				//parallax= {true}
				slidesPerView= {5}
				spaceBetween= {30}
				loop={true}
				autoplay= {{
				   delay: 3000,
				}}
				modules={[ Autoplay ]}
				breakpoints = {{
					1191: {
                        slidesPerView: 5,
                    },
                    992: {
                        slidesPerView: 4,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    575: {
                        slidesPerView: 2,
                    },
                    320: {
                        slidesPerView: 2,
                    },
				}}
			>	
               
				{dataBlog.map((d,i)=>(
					<SwiperSlide key={i}>						
                        <div className="clients-logo">
                            <img className="logo-main" src={d.image} alt="" />
                        </div>				
					</SwiperSlide>
				))}				
			</Swiper>
        </>
    );
};


export default PartnershipSlider;
