import React,{useRef} from 'react';
import emailjs from '@emailjs/browser';
import swal from "sweetalert";
import {Link, useNavigate} from 'react-router-dom';
//layouts
import { Accordion } from 'react-bootstrap';
import PageBanner from '../layouts/PageBanner';
import PartnershipSlider from '../components/Home/PartnershipSlider';

//images
import bg from '../assets/images/banner/bnr3.jpg';
import project from '../assets/images/project/pic3.jpg';
import slide from '../assets/images/main-slider/pic2.png';
import shape1 from './../assets/images/pattern/shape1.png';
import shape2 from './../assets/images/pattern/shape2.png';
import shape3 from './../assets/images/pattern/shape3.png';
import shape5 from './../assets/images/pattern/shape5.png';
import shape6 from './../assets/images/pattern/shape6.png';
import TestimonialSlider from '../components/Home/TestimonialSlider';
import UpdateBlog from '../components/Home/UpdateBlog';
import CategoriesIcon from '../components/CategoriesIcon';

const accordBlog = [
    {title:"How can I donate to Citizenscall-ERRI?",body: "You can donate to CitizensCall-ERRI in a variety of ways. You can make a donation through our Go Fund Me  Account, through Bank Transfer, and lastly through visiting our office to deliver your donation."},
    {title:"What are the different ways I can support CitizensCall-ERRI?", body: "In addition to donating money, there are a number of other ways you can support CitizensCall-ERRI. You can volunteer your time, donate goods or services, or attend our fundraising events."},
    {title:"What are the benefits of supporting Citizenscall-ERRI?", body: "When you support CitizensCall-ERRI, you are helping to make a real difference in the lives of people in need. Your donation will help us to provide essential services to those who need them most."},
];

const Donate = () => {
    const navigate = useNavigate();
    const form = useRef();
    const handleSubmit = (e) =>{
        e.preventDefault();
        //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
		emailjs.sendForm('service_gfykn6i', 'template_iy1pb0b', e.target, 'HccoOtZS6GHw-N-m6')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
        swal('Good job!', 'form successfuly submmited', "success");
        navigate("#");
    }
    return (
        <>
            <div className="page-content bg-white">
                <PageBanner maintitle="Home" pagetitle="Support our course" background={"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1697192788/erri-website/347410530_160517920361115_5790574256184091743_n_uabdfi.jpg"} />    
                <section className="content-inner">
                    <div className="container">
                        <div className="row align-items-center flex-column-reverse flex-lg-row">
                            <div className="col-lg-6 align-self-center">
                                <div className="section-head m-b30">
                                    <h2 className="title">How to Donate and Support the Citizenscall-ERRI ?</h2>
                                    <p>At Citizenscall-ERRI, works to improve the lives of people affected by crises around South Sudan. You can take part by making a small donation or anyother form of support to the organisation</p>
                                </div>
                                <Accordion className="accordion dz-accordion accordion-sm" id="accordionFaq1">
                                    {accordBlog.map((item, index)=>(
                                        <Accordion.Item className="accordion-item" key={index} eventKey={index}>
                                            <Accordion.Header as="h2"  id="headingOne1">
                                                {item.title}
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne1" className="accordion-collapse " eventKey={index}>
                                                <Accordion.Body >
                                                    <p className="m-b0">{item.body}</p>
                                                </Accordion.Body>
                                            </div>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div>
                            <div className="col-lg-6 order-lg-2 order-1 m-b30">
                                <div className="dz-media split-box rounded">
                                    <img src={"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1697192774/erri-website/375670875_207759668970273_4435196891753281332_n_1_ysdgkg.jpg"} alt="FAQ Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content-inner-2 section-wrapper6 bg-light section-pattren1">
                    <div className="container">
                        <div className="row align-items-end">
                            {/*<div className="col-lg-5 about-bx3">
                                <div className="dz-media">
                                    <img src={slide} alt="image" />
                                </div>
                            </div>*/}
                            <div className="col-lg-7">
                                <div className="form-wrapper style-2">
                                    <h2 className="title">Pledge your support to Citizenscall-ERRI</h2>
                                    <div className="contact-area">
                                        <form className="dz-form dzForm contact-bx" ref={form} onSubmit={(e)=>handleSubmit(e)}>
                                            <div className="dzFormMsg"></div>
                                            <input type="hidden" className="form-control" name="dzToDo" value="Contact" />
                                            <input type="hidden" className="form-control" name="reCaptchaEnable" value="0" />
                                            
                                            <div className="row sp15">
                                                <div className="col-md-12">
                                                    <label className="form-label">Full Name</label>
                                                    <div className="input-group">
                                                        <input name="dzName" required type="text" className="form-control" placeholder="Marchelo Queque" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label">Email address</label>
                                                    <div className="input-group">
                                                        <input name="dzEmail" required type="text" className="form-control" placeholder="example@gmail.com" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label">Phone Number</label>
                                                    <div className="input-group">
                                                        <input name="dzPhoneNumber" required type="text" className="form-control" placeholder="832141251" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <label className="form-label">organisation Name</label>
                                                    <div className="input-group">
                                                        <input name="dzOther[company_Name]" required type="text" className="form-control" placeholder="Marchelo Studios" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <label className="form-label">Message</label>
                                                    <div className="input-group">
                                                        <textarea name="dzMessage" rows="5" required className="form-control" placeholder="Dear Sir/Madam"></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button name="submit" type="submit" value="Submit" className="btn btn-primary effect ">Donate</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>	
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={shape1} className="shape-1 move-1" alt="shape"/>
                    <img src={shape2} className="shape-2 move-2" alt="shape"/>
                    <img src={shape3} className="shape-3 move-1" alt="shape"/>
                    <img src={shape5} className="shape-4 rotating" alt="shape"/>
                    <img src={shape6} className="shape-5 rotating" alt="shape"/>
                    <img src={shape6} className="shape-6 rotating" alt="shape"/>
                </section>
                <section className="clients-wrapper p-0">
                    <div className="container">
                        <div className="section-head text-center">
                            <h3 className="title">Our Partners</h3>
                        </div>
                        <PartnershipSlider />
                    </div>
                </section>
                <section className="testimonial-wrapper1 content-inner-2">
                    <div className="section-head text-center">
                        <h2 className="title">Testimonials</h2>
                        <p>Here's what people are saying about Citizenscall-ERRI</p>
                    </div>
                    <TestimonialSlider />
                </section>
                <div className="call-action style-1 content-inner-1">
                    <div className="container">
                        <UpdateBlog />
                    </div>
                </div>
            </div>            
        </>
    );
};


export default Donate;