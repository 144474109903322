import React,{useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import { client,urlFor } from '../lib/sanity';

//Images
import bgImage from './../assets/images/banner/bnr5.jpg';
import pic1 from '../assets/images/gallery/1 (1).jpg';
import pic2 from '../assets/images/gallery/1 (2).jpg';
import pic3 from '../assets/images/gallery/1 (3).jpg';
import pic5 from '../assets/images/gallery/1 (5).jpg';
import pic6 from '../assets/images/gallery/1 (6).jpg';
import pic7 from '../assets/images/gallery/1 (7).jpg';
import pic8 from '../assets/images/gallery/1 (8).jpg';
import pic9 from '../assets/images/gallery/1 (9).jpg';
import pic10 from '../assets/images/gallery/1 (10).jpg';
import pic11 from '../assets/images/gallery/1 (11).jpg';
import pic12 from '../assets/images/gallery/1 (12).jpg';
import pic13 from '../assets/images/gallery/1 (13).jpg';
import pic14 from '../assets/images/gallery/1 (14).jpg';
import pic15 from '../assets/images/gallery/1 (15).jpg';
import pic16 from '../assets/images/gallery/1 (16).jpg';
import pic17 from '../assets/images/gallery/1 (17).jpg';
import pic18 from '../assets/images/gallery/1 (18).jpg';
import pic19 from '../assets/images/gallery/1 (19).jpg';
import pic20 from '../assets/images/gallery/1 (20).jpg';
import pic21 from '../assets/images/gallery/1 (21).jpg';
import pic22 from '../assets/images/gallery/1 (22).jpg';
import pic23 from '../assets/images/gallery/1 (23).jpg';
import pic24 from '../assets/images/gallery/1 (24).jpg';
import pic25 from '../assets/images/gallery/1 (25).jpg';
import pic26 from '../assets/images/gallery/1 (26).jpg';
import pic27 from '../assets/images/gallery/1 (27).jpg';
import pic28 from '../assets/images/gallery/1 (28).jpg';
import pic29 from '../assets/images/gallery/1 (29).jpg';
import pic30 from '../assets/images/gallery/1 (30).jpg';




//Component
import PageBanner from '../layouts/PageBanner';
import UpdateBlog from '../components/Home/UpdateBlog';
import Gallery from '../components/Home2/Gallery';

const imagesGallery = [
    {image:pic1 },
    {image:pic2 },
    {image:pic3 },
    {image:pic5 },
    {image:pic6 },
    {image:pic7 },
    {image:pic9 },
    {image:pic10},
    {image:pic11},
    {image:pic12},
    {image:pic13},
    {image:pic14},
    {image:pic15},
    {image:pic16},
    {image:pic17},
    {image:pic18},
    {image:pic19},
    {image:pic20},
    {image:pic21},
    {image:pic22},
    {image:pic23},
    {image:pic24},
    {image:pic25},
    {image:pic26},
    {image:pic27},
    {image:pic28},
    {image:pic29},
    {image:pic30},
];
const GalleryPage = () => {
    const onInit = () => {
      //  console.log('lightGallery has been initialized');
    };

    const [gallery,setGallery] = useState()
    useEffect(() => {
		client
        .fetch(
            `*[_type == "gallery"][0]{
                _id,
                images[] {
                  asset->{
                    _id,
                    url
                  },
                },
            }`
        )
        .then((data) => setGallery(data))
        .catch(console.error);
	},[]);




    return (
        <>
            <div className="page-content bg-white">
              <PageBanner maintitle="Home" pagetitle="Our Gallery" background={"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1696375352/erri-website/1_30_krjovm.jpg"} />
                <div className="content-inner">
                    <div className="container">                      
                           
                        <LightGallery
                            onInit={onInit}
                            speed={500}
                            plugins={[lgThumbnail, lgZoom]}
                            elementClassNames="row mosaic-gallery"
                        >
                            {/*gallery && gallery.images.map((item, index)=>(          
                                <picture data-exthumbimage={urlFor(item.image)} data-src={urlFor(item.image)}  title="Image" className="col-xl-3 col-lg-4 col-6 m-b30 d-flex" key={index}>
                                    <img  src={urlFor(item.image)} alt="" style={{objectFit: "cover"}}/>
                                </picture>    
                            ))*/}
                            {gallery && gallery.images.map((item, index)=>(
                                    <picture data-exthumbimage={urlFor(item).url()} data-src={urlFor(item).url()} title="Image" className="col-xl-3 col-lg-4 col-6 m-b30 d-flex" key={index}>
                                        <img  src={urlFor(item).url()} alt="" style={{objectFit: "cover"}}/>
                                    </picture>
                                ))
                            }
                        </LightGallery>                        
                    </div>
                </div>
            </div>
            <hr className="container my-0" />
		
            <section className="content-inner-2">
                <div className="container">
                    <div className="section-head text-center mb-20">
                        <h6 className="sub-title">A SHORT BRIEF</h6>
                        <h2 className="title">To Learn More</h2>
                        <p>Feel free to contact us for more information about us. Amd follow us on social media to get updates and news.</p>
                        <Link to={"/contact-us"} className="btn btn-primary">Contact Us</Link>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GalleryPage;