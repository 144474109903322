import React, {useRef} from 'react';
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
//import "swiper/css/pagination";

import { Autoplay, Navigation, EffectFade } from "swiper";

import DonateModal from '../Modal/DonateModal';


/*import pic1 from "../../assets/images/gallery/1 (22).jpg";
import pic2 from "../../assets/images/gallery/1 (4).jpg";
import pic3 from "../../assets/images/gallery/1 (19).jpg";*/

const pic2 = "https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698616758/erri-website/hero1_seqtj7.jpg";
const pic1 = "https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698225034/erri-website/WhatsApp_Image_2023-10-25_at_10.42.20_AM_tmahli.jpg";
const pic3 = "https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698616763/erri-website/hero2_tmlkkw.jpg";


	const dataBlog1 =	{title1:"GIVE A HAND TO MAKE", title2:"Providing humanitarian aid to people affected by man-made and natural crisis.",baner:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1697144557/erri-website/347425682_149130648166509_1570927059663776144_n_omnnja.jpg", img2: pic1, img3: pic2, img4:pic3 }
	

const Mainslider2 = ({onShowDonate}) => {
    const navigationPrevRef = React.useRef(null)
	const navigationNextRef = React.useRef(null)
    //const paginationRef = React.useRef(null)
	const modalRef = useRef(null);
    return (
        <>
            <>	  						
				<div className="banner-inner" style={{backgroundImage: "url("+ dataBlog1.baner +")"}}>
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-6 col-md-12">
								<div className="banner-content">
									<div className="top-content">
										{/*<h5 className="sub-title text-secondary">{dataBlog1.title1}</h5>*/}
										<h1 className="title">{dataBlog1.title2} </h1>
										<h2 style={{color: "#fff",fontStyle: "italic", }}>"Saving lives and restoring hope"</h2>
										<div className="d-flex align-items-center">
											<Link to={"/donate"} className="btn btn-danger btnhover1" 
												//onClick={()=>onShowDonate(true)}
												onClick={() => {modalRef.current.handleModalOpen(); }}
											>
												<span>Donate Now</span>
											</Link>
											<Link to={"/about-us"} className="btn btn-secondary btnhover2">Learn More </Link>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-6 col-md-12">
								<div className="banner-media row gx-1">
									<div className="col-5">
										<div className="main-img1">
											<img src={dataBlog1.img2} alt="" />
										</div>
										<div className="main-img2">
											<img src={dataBlog1.img3} alt="" />
										</div>
									</div>
									<div className="col-7">	
										<div className="main-img3">
											<img src={dataBlog1.img4} alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>			
			</>
			<DonateModal 
                ref={modalRef}
            />
        </>
    );
};


export default Mainslider2;