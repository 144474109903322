import React from 'react';
import {Link} from 'react-router-dom';
import imageUrlBuilder from "@sanity/image-url";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
//import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper styles
import "swiper/css";

//Images
import pic1 from './../../assets/images/gallery/1 (25).jpg';
import pic2 from './../../assets/images/gallery/1 (13).jpg';
import pic3 from './../../assets/images/gallery/1 (4).jpg';
import pic4 from './../../assets/images/gallery/1 (2).jpg';
import pic5 from './../../assets/images/gallery/1 (27).jpg';
import pic6 from './../../assets/images/gallery/1 (27).jpg';



// import Swiper core and required modules
import { Autoplay } from "swiper";
import { useEffect } from 'react';
import { client, getPosts } from '../../lib/sanity';
import { useState } from 'react';

//SwiperCore.use([EffectCoverflow,Pagination]);

const builder = imageUrlBuilder(client);

function urlFor(source) {
	return builder.image(source);
}

const RecentProjectsSlider = () => {
    const [posts,setPosts] = useState()
    useEffect(()=>{
        client
        .fetch(
            `*[_type == "project"]{
            title,
            slug,
            category->{
                title
            },
            mainImage{
                asset->{
                _id,
                url
                },
            },
            }`
        )
        .then((data) => setPosts(data))
        .catch(console.error);
    })

    return (
        <>
             
            <Swiper className="recent-blog p-b5"
				speed= {1500}
				parallax= {true}
				slidesPerView= {3}
				spaceBetween= {30}
				loop={true}
				autoplay= {{
				   delay: 3000,
				}}
				modules={[ Autoplay ]}
				breakpoints = {{
					1200: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    320: {
                        slidesPerView: 1,
                    },
				}}
			>	
               
				{posts && posts.map((project,id)=>(
					<SwiperSlide key={id}>						
                        <div className="dz-card style-2 overlay-skew wow fadeInUp" data-wow-delay="0.2s">
                            <div className="dz-media">
                                <Link to={"/fundraiser-detail"} className='d-flex'><img src={urlFor(project.mainImage).url()} alt="" className='w-100 h-50'/></Link>
                            </div>
                            <div className="dz-info">
                                <ul className="dz-category">
                                    <li><Link to={"project/"+ project.slug.current}>{project.category.title}</Link></li>
                                </ul>
                                <h5 className="dz-title"><Link to={"project/"+ project.slug.current}>{project.title}</Link></h5>
                            </div>
                        </div>			
                    </SwiperSlide>
				))}				
			</Swiper>
        </>
    );
};

export default RecentProjectsSlider;
