import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import imageUrlBuilder from "@sanity/image-url";
import { client} from '../../lib/sanity';

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import img1 from '../../assets/images/blog/large/pic1.jpg';
import img2 from '../../assets/images/blog/large/pic2.jpg';
import img3 from '../../assets/images/blog/large/pic3.jpg';
import img4 from '../../assets/images/blog/large/pic4.jpg';
import img5 from '../../assets/images/blog/large/pic5.jpg';
import img6 from '../../assets/images/blog/large/pic6.jpg';

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

const swiperFirst = [
    {image: img1},{image: img2},
    {image: img3},{image: img4},
    {image: img5},{image: img6},
];

const builder = imageUrlBuilder(client);

function urlFor(source) {
	return builder.image(source);
}

export default function GallerySlider2({images}) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <Swiper 
        spaceBetween={10}
        navigation={true}
        thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
        modules={[FreeMode, Navigation, Thumbs]}
        className="fundraiser-gallery-swiper"
      >
        {images && images.map((i)=>(
            <SwiperSlide key={i._id}>
                <div className="dz-media"><img src={urlFor(i).url()} alt="" /></div>
            </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="fundraiser-gallery-thumb"
      >
        {images && images.map((i)=>(
            <SwiperSlide key={i._id}>
                <div className="dz-media"><img src={urlFor(i).url()} alt="" /></div>
            </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
