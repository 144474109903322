import React,{useRef} from 'react';
import {Link} from 'react-router-dom';
import emailjs from '@emailjs/browser';
import swal from "sweetalert";

const bgimage = 'https://res.cloudinary.com/dqwlxo5fe/image/upload/v1697148823/erri-website/375670875_207759668970273_4435196891753281332_n_1_j3tlpq.jpg';

const UpdateBlog = () =>{
    const form = useRef();
	const sendEmail = (e) => {
		e.preventDefault();
		emailjs.sendForm('gmail', 'YOUR_TEMPLATE_ID', e.target, 'd9b2e0f5fc72cb94792110e8ff2028f3-us16')
		  .then((result) => {
			  console.log(result.text);
		  }, (error) => {
			  console.log(error.text);
		  });
		  e.target.reset()
          swal('Good job!', 'form successfuly submmited', "success");
          //alert('form suucessfult submmit');
	 };	
    return(
        <>
            
            <div className="inner-action overlay-primary-dark" style={{backgroundImage:"url("+ bgimage + ")"  ,backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                <div className="row inner-content justify-content-center">
                    <div className="col-xl-6 col-lg-9">
                        <div className="section-head text-center">
                            <h2 className="title text-white">Support our course</h2>
                            <p>Support our course by making a donation to our GoFundMe account</p>
                        </div>
                        <div>
                            <a target="_blank" className="btn btn-secondary btnhover2"  rel="noreferrer" href="https://gofund.me/e20c50dd">GoFundme</a>
						</div>
                    </div>
                </div>
            </div>
                
        </>
    )
}
export default UpdateBlog;