import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LightGallery from 'lightgallery/react';
import block from "../../assets/filles/ministry.json"
import category from "../../assets/filles/category.json";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

//Images
import pic1 from '../../assets/images/gallery/1 (1).jpg';
import pic2 from '../../assets/images/gallery/1 (2).jpg';
import pic3 from '../../assets/images/gallery/1 (3).jpg';
import pic4 from '../../assets/images/gallery/1 (4).jpg';
import pic5 from '../../assets/images/gallery/1 (5).jpg';
import pic6 from '../../assets/images/gallery/1 (6).jpg';
import pic7 from '../../assets/images/gallery/1 (7).jpg';
import pic8 from '../../assets/images/gallery/1 (9).jpg';
import pic9 from '../../assets/images/gallery/1 (10).jpg';

const imagesGallery = [
    {image:pic1 },
    {image:pic2 },
    {image:pic3 },
    {image:pic5 },
    {image:pic6 },
    {image:pic7 },
    {image:pic8 },
    {image:pic9 },
];

const Gallery = () => {
    const [filteredProducts, setFilteredProducts] = useState(block);
    const [activeItem, setActiveItem] = useState(0);

    const onInit = () => {
        //  console.log('lightGallery has been initialized');
    };

    return (
        <div className="content-inner">
        <div className="container">                      
            <div className="section-head text-center">
                <h2>Gallery</h2>
                <p> Here, you will find a collection of stunning images that showcase our work, activities, and the experiences.</p>
            </div>
                 
            <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
                elementClassNames="row"
            >
                {imagesGallery.map((item, index)=>(          
                    <picture data-exthumbimage={item.image} data-src={item.image}  title="Image" className="col-xl-3 col-lg-4 col-6 m-b30 d-flex" key={index}>
                        <img  src={item.image} alt="" style={{objectFit: "cover"}}/>
                    </picture> 
                ))}
            </LightGallery>                        
        </div>
    </div>
    );
};

export default Gallery;
