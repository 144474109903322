import React,{useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { format } from 'date-fns';
import { client, urlFor } from '../lib/sanity';


import PageBanner from '../layouts/PageBanner';

//images
import bg from '../assets/images/banner/bnr4.jpg';

import {cardDetials} from './Blog';
import UpdateBlog from '../components/Home/UpdateBlog';


const BlogGrid = () => {

    const [posts,setPosts] = useState()
    useEffect(()=>{
        client
        .fetch(
            `*[_type == "post"]{
                _id,
                title,
                body[0]{
                    children[0]{
                      text
                    }
                },
                publishedAt,
                category->{
                  title
                },
                mainImage{
                  asset->{
                    _id,
                    url
                  },
                },
                author->{
                  name,
                  image
                },
                slug
              }`
        )
        .then((data) => setPosts(data))
        .catch(console.error);

        console.log(posts)
    },[])


    return (
        <>
            <div className="page-content bg-white">
                <PageBanner maintitle="Home" pagetitle="Blog" background={"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1697192783/erri-website/347398423_160151707064403_7732155480169220459_n_mxhd4i.jpg"}/>
                <section className="content-inner-2">
                    <div className="container">
                        <div className="row">
                            {posts && posts.map((i)=>(
                               <Blog blog={i} key={i._id}/>
                            ))}                            
                        </div>
                         {/*<div className="row">		
                            <div className="col-12 m-sm-t0 m-t30">		
                                <nav  className="pagination-bx">
                                    <div className="page-item">
                                        <Link to={"#"} className="page-link prev"><i className="fas fa-chevron-left"></i></Link>
                                    </div>
                                    <ul className="pagination">
                                        <li className="page-item"><Link to={"#"} className="page-link">1</Link></li>
                                        <li className="page-item"><Link to={"#"} className="page-link active">2</Link></li>
                                        <li className="page-item"><Link to={"#"} className="page-link">3</Link></li>
                                        <li className="page-item"><Link to={"#"} className="page-link">4</Link></li>
                                    </ul>
                                    <div className="page-item">
                                        <Link to={"#"} className="page-link next"><i className="fas fa-chevron-right"></i></Link>
                                    </div>
                                </nav>
                            </div>
                        </div> */}
                    </div>
                </section>
                <div className="call-action style-1 content-inner-1">
				    <div className="container">
                        <UpdateBlog />
                    </div>
                </div>
            </div>
        </>
    );
};

const Blog = ({blog})=>{
    return(
        <div className="col-xl-4 col-md-6 m-b30">
            <div className="dz-card style-1">
                <div className="dz-media">
                    <Link to={"/blog/"+blog.slug.current}><img src={urlFor(blog.mainImage).url()} alt="" /></Link>
                    <ul className="dz-badge-list">
                        <li><Link to={"#"} className="dz-badge">{blog.category.title}</Link></li>
                    </ul>
                    <Link to={"/blog/"+blog.slug.current} className="btn btn-secondary">Read More</Link>
                </div>
                <div className="dz-info">
                    <h5 className="dz-title"><Link to={"/blog/"+blog.slug.current}>{blog.title} </Link></h5>
                    <p className='dz-title'>{blog.body.children.text}</p>                                            
                    <div className="author-wrappper">
                        <div className="author-media">
                            <img src={""} alt={blog.author.name} /> 
                        </div>
                        <div className="author-content">
                            <div className="author-head">
                                <h6 className="author-name">{blog.author.name}</h6>
                            </div>
                            <ul className="author-meta">
                                <li className="date">{format(new Date(blog.publishedAt), "dd MMMM yyyy")}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogGrid;