import React,{useState,useContext, useEffect,} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import CountUp from 'react-countup'
import {Modal} from 'react-bootstrap';
import TestimonialSlider from '../components/Home/TestimonialSlider';
import { format } from "date-fns"
import { client, urlFor } from '../lib/sanity';


//images
//import bg from "../assets/images/background/bg2.jpg";
import slide2 from '../assets/images/side-images/pic2.png';
import bg10 from '../assets/images/background/bg10.png';
import sideimage from '../assets/images/side-images/pic1.png';
import shape3 from '../assets/images/side-images/shape3.png';
import bgimage10 from '../assets/images/background/bg10.jpg';
import map from '../assets/images/map.png';
import shape1 from '../assets/images/side-images/shape1.png';
import line1 from '../assets/images/side-images/shape-line1.png';
import shape2 from '../assets/images/side-images/shape2.png';
import line2 from '../assets/images/side-images/shape-line2.png';
import shape5 from '../assets/images/pattern/shape5.png';
import shape6 from '../assets/images/pattern/shape6.png';
import bnrgrid from '../assets/images/blog/blog-grid-2/pic1.jpg';
import grid1 from '../assets/images/blog/blog-grid/pic1.jpg';
import grid2 from '../assets/images/blog/blog-grid/pic2.jpg';

//Layouts
import { ThemeContext } from "../context/ThemeContext";
import Header2 from '../layouts/Header2';
import Footer from './../layouts/Footer';
import { IMAGES } from '../constant/theme';
//componenet
import Mainslider2 from '../components/Home2/Mainslider2';
import ServiceBlog from '../components/Home2/ServiceBlog';
import {ThreeStepBlog} from '../components/Home2/ServiceBlog';
import TrendingSlider from '../components/Home2/TrendingSlider';
import SuccessSlider from '../components/Home2/SuccessSlider';
import PartnershipSlider from './../components/Home/PartnershipSlider';
import FooterTop from './../components/Home2/FooterTop';
import RecentProjectsSlider from '../components/Home/RecentProjectsSlider';
import Gallery from '../components/Home2/Gallery';



const priceBlog = [
    {price:"10"},
    {price:"25"},
    {price:"50"},
    {price:"100"},
];

const bg = "https://res.cloudinary.com/dqwlxo5fe/image/upload/v1697144649/erri-website/355695085_161510800261827_965742205506141442_n_cq2h33.jpg"

const griddataBlog = [
    {image:grid1, title:"", author:"", date:"Aug 2023"},
    {image:grid2, title:"", author:"", date:""}
];

const Home2 = () => {
    const { changeBackground, changePrimaryColor } = useContext(ThemeContext);
    const [news,setNews] = useState([]);
    const [first,setFirst] = useState()

	useEffect(() => {
		/*changeBackground({ value: "data-typography-1", label: "data-typography-1" });
		changePrimaryColor("color-skin-2");*/
	}, []);

    useEffect(() => {
		client
        .fetch(
            `*[_type == "news"][0]{
                _id,
                title,
                publishedAt,
                author->{
                    name,
                    image{
                      asset->{
                        _id,
                        url
                      }
                    }
                },
                mainImage{
                  asset->{
                    _id,
                    url
                  },
                },
                slug
              }`
        )
        .then((data) => setFirst(data))
        .catch(console.error);
	}, []);

    useEffect(()=>{
        client
        .fetch(
            `*[_type == "news"][0...3]{
                _id,
                title,
                publishedAt,
                body[0]{
                    children[0]{
                      text
                    }
                },
                category->{
                  title
                },
                mainImage{
                  asset->{
                    _id,
                    url
                  },
                },
                author->{
                    name,
                    image{
                      asset->{
                        _id,
                        url
                      }
                    }
                },
                slug
              }| order(publishedAt desc)`
        )
        .then((data) => setNews(data))
        .catch(console.error);

        console.log(news)
    },[])

    const [donateValue, setDonateValue] = useState(priceBlog[0].price);
    function changeValue(price){
        setDonateValue(price);
    }
    const nav = useNavigate();
    const submitHandler = (e) => {
        e.preventDefault();
        nav("/contact-us");
    };

    // const [modalDonate, setModalDonate] = useState(false);
    
    const [readModal, setReadModal] = useState(false);
    return (
        <>
            <Header2  logoStyle={IMAGES.logo2}/>
            <div className="page-content bg-white">	
                <div className="main-bnr-one">
                    <Mainslider2 />
                </div>
                {/**  */}
                <section className="content-inner section-wrapper4">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6 ">
                                <div className="donate-form form-wrapper" style={{backgroundImage:"url("+ bg + ")",color:"#fff"}}>
                                    <div>
                                        <h2 className="title m-b20">Help us make a difference. Donate today</h2>
                                        <p>Your donation will help us to continue our important work of evacuating and rehabiliting the people affected by crises. Together, we can make a positive impact on the world.</p>
                                    </div>
                                    <Link to={"/donate"} className="btn btn-danger btnhover1">Donate Now</Link>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-6 m-b30">
                                <div className="section-inner">
                                    <div className="section-head m-b30">
                                        <h5 className="sub-title">About Us</h5>
                                    </div>
                                    <div className="row m-b30">
                                        <div className="col-xl-7">
                                            <p>The Citizens’ Call - Emergency Response and Rehabilitation Initiative (ERRI) is a national non-political and not-for-profit organisation founded in Juba in April 2023 with a mission to respond  to human and natural crisis in South Sudan by providing affected people with humanitarian relief and rehabilitation.</p>
                                        </div>
                                        {/*<div className="col-xl-5">
                                            <div className="counter-text-wrapper">
                                                <div className="counter-text m-b30">
                                                    <h4>Donation Raised</h4>
                                                    <div className="counter-num text-primary">
                                                        $<span className="counter">
                                                            <CountUp end="55000" separator=','/>
                                                        </span> 
                                                    </div>
                                                </div>
                                                <div className="counter-text">
                                                    <h4>Donation Goal</h4>
                                                    <div className="counter-num text-primary">
                                                        $<span className="counter">
                                                            <CountUp end="25000" separator=','/>    
                                                        </span> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/}
                                    </div>
                                    <Link to={"/about-us"} className="btn btn-primary">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<img className="img-1" src={slide2} alt="images" />*/}
                </section>
                {/** */}
                <section className="content-inner-2 overflow-hidden">
                    <div className="container">
                        <div className="section-head text-center">
                            <h2>Recent Projects</h2>
                            <p>Check out our most recent projects</p>
                        </div>
                        <RecentProjectsSlider />
                    </div>   
                </section>
                {/*<section className="content-inner-1 section-wrapper3">
                    <div className="container">
                        <div className="section-head text-center">
                            <h6 className="sub-title">START YOUR FUNDRAISER</h6>
                            <h2 className="title">Start A Fundraiser In Three<br/> Simple Steps</h2>
                        </div>
                        <div className="row justify-content-center">
                            <ThreeStepBlog />
                        </div>
                        <div className="text-center btn-bottom wow fadeInUp" data-wow-delay="0.8s">
                            <Link to={"/browse-fundraiser"} className="btn btn-primary">Start your fundraiser</Link>
                        </div>
                        <img src={sideimage} alt="images" className="img-1" />
			            <img src={shape3} alt="images" className="img-2" />
                    </div>
                </section>*/}
                <div className="content-inner-3 section-wrapper7">
                    <div className="container">
                        {/*<div className="map-wrapper">
                            <img src={map} className="main-img" alt=""/>
                            <ul>
                                <li className="icon-dropdown">
                                    <div className="right"><p>It is 30% poor in the country</p><Link to={"#"} className="btn-link" data-bs-toggle="modal" data-bs-target="#modalDonate">Donate Now</Link></div>
                                </li>
                                <li className="icon-dropdown">
                                    <div className="right"><p>It is 50% poor in the country</p><Link to={"#"} className="btn-link" data-bs-toggle="modal" data-bs-target="#modalDonate">Donate Now</Link></div>
                                </li>
                                <li className="icon-dropdown">
                                    <div className="left"><p>It is 25% poor in the country</p><Link to={"#"} className="btn-link" data-bs-toggle="modal" data-bs-target="#modalDonate">Donate Now</Link></div>
                                </li>
                            </ul>
                        </div>*/}
                        <div className="counter-wrapper-2">
                            <div className="counter-inner">
                                <div className="row">
                                    <div className="col-sm-4 m-b30 wow fadeInUp" data-wow-delay="0.2s">
                                        <div className="counter-style-2">
                                            <span className="counter-num text-primary">
                                                <span className="counter">
                                                    <CountUp end="10000" enableScrollSpy={true}/>
                                                </span>+
                                            </span>
                                            <p className="counter-text">People Evacuated</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 m-b30 wow fadeInUp" data-wow-delay="0.4s">
                                        <div className="counter-style-2">
                                            <span className="counter-num text-primary">
                                                <span className="counter">
                                                    <CountUp end="335078" enableScrollSpy={true} />
                                                </span>+
                                            </span>
                                            <p className="counter-text">Returnees</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 m-b30 wow fadeInUp" data-wow-delay="0.6s">
                                        <div className="counter-style-2">
                                            <span className="counter-num text-primary">
                                                <span className="counter">
                                                    <CountUp end="100" enableScrollSpy={true}/>
                                                </span> +
                                            </span>
                                            <p className="counter-text">Volunteers</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<img src={shape1} alt="image" className="shape1" />
                    <img src={line1} alt="image" className="shape2" />
                    <img src={shape2} alt="image" className="shape3" />
                    <img src={line2} alt="image" className="shape4" />
                    <img src={shape5} alt="image" className="shape5" />
                    <img src={shape6} alt="image" className="shape6" />
                    
                    <img src={shape5} alt="image" className="shape7" />*/}
                </div>
                {/*<section className="clearfix section-wrapper7">
                    <div className="container-fluid">
                        <div className="content-inner bg-gray section-inner" style={{backgroundImage:"url("+ bg10 + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
                            <div className="section-head text-center">
                                <h5 className="sub-title">LATEST CAUSES</h5>
                                <h2>Trending Fundraisers</h2>
                            </div>
                            <TrendingSlider />
                            <div className="text-center m-t30 m-b30 wow fadeInUp" data-wow-delay="1.0s">
                                <Link to={"/browse-fundraiser"} className="btn btn-primary" >View All Causes</Link>
                            </div>
                        </div>
                    </div>
                </section>*/}
                <section className="testimonial-wrapper1 content-inner-2">
                    <div className="section-head text-center">
                        <h2 className="title">Testimonials</h2>
                        <p>What people are saying about Citizenscall-ERRI.</p>
                    </div>
                    <TestimonialSlider />
                </section>
                {/**  */}
                <section className="content-inner-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-lg-12">
                                <div className="section-head wow fadeInUp" data-wow-delay="0.2s">
                                    <h5 className="sub-title">Our News</h5>
                                    <h2 className="title">News</h2>
                                </div>
                                {news && news.map((item, ind)=>(
                                    <div key={ind} className="dz-card style-6 blog-half">
                                        <div className="dz-media">
                                            <Link to={"/news/"+item.slug.current}><img src={urlFor(item.mainImage).url()} alt=""/></Link>
                                            <ul className="dz-badge-list">
                                                <li><Link to={"#"} className="dz-badge">{format(new Date(item.publishedAt), "dd MMMM yyyy")}</Link></li>
                                            </ul>
                                            <Link to={"/news/"+item.slug.current} className="btn btn-secondary">Read More</Link>
                                        </div>
                                        <div className="dz-info">
                                            <h4 className="dz-title"><Link to={"/news/"+item.slug.current}>{item.title}</Link></h4>
                                            <div className="dz-meta">
                                                <ul>
                                                    <li className="dz-user">
                                                        <i className="fa-solid fa-user"></i>
                                                        By <span>{item.author.name}</span>
                                                    </li>
                                                    {/*<li className="dz-date">
                                                        <i className="fa-solid fa-message"></i>
                                                        24 Comments
                                                    </li>*/}
                                                </ul>
                                            </div> 
                                            <p className='dz-title'>{item.body.children.text}</p>
                                        </div>
                                    </div>
                                ))}                                
                            </div>
                            {
                             first && (
                                <div className="col-xl-5 col-lg-12 m-b30 wow fadeInUp" data-wow-delay="0.6s">
                                <div className="dz-card style-7" style={{backgroundImage: "url("+ urlFor(first.mainImage).url() +")"}}>
                                    <div className="dz-category">
                                        <ul className="dz-badge-list">
                                            <li><Link to={"#"} className="dz-badge">{format(new Date(first.publishedAt), "dd MMMM yyyy")}</Link></li>
                                        </ul>
                                    </div>
                                    <div className="dz-info">
                                        <h2 className="dz-title"><Link to={"/news/"+first.slug.current} className="text-white">{first.title}</Link></h2>
                                        <div className="dz-meta">
                                            <ul>
                                                <li className="dz-user">
                                                    <i className="fa-solid fa-user"></i>
                                                    By <span>{first.author.name}</span>
                                                </li>
                                                {/*<li className="dz-date">
                                                    <i className="fa-solid fa-message"></i>
                                                    24 Comments
                                                </li>*/}
                                            </ul>
                                        </div>
                                    </div>							
                                </div>
                            </div>
                             )
                            }
                        </div>
                    </div>
                </section>
                {/**Partners  */}
                {/*<div className="clients-wrapper">
			        <div className="container">
                        <PartnershipSlider />
                    </div>
                </div>*/}
                {/*<div className="footer-feature-wrapper">
                    <div className="container">
                        <FooterTop />
                    </div>
                </div>*/}
                <Gallery/>
            </div>
           <Footer/>
            <Modal className="modal fade modal-wrapper" id="read" centered show={readModal} onHide={setReadModal}> 
                <div className="modal-body">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                </div>                
            </Modal>
            
        </>
    );
};

export default Home2;