import React,{useState, useEffect,} from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {client, urlFor} from '../../lib/sanity';
//import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper styles
//import "swiper/css";

//Images
import pic1 from './../../assets/images/testimonials/pic1.jpg';
import pic2 from './../../assets/images/testimonials/pic2.jpg';
import pic3 from './../../assets/images/testimonials/pic3.jpg';

// import Swiper core and required modules
import { Autoplay, Navigation, Pagination } from "swiper";
import { PortableText } from '@portabletext/react';

//SwiperCore.use([EffectCoverflow,Pagination]);

const dataBlog = [
	{image: pic1, title:"Johan Lee", text:"Thank you, people of Juba. We are now leaving Jebel Aulia with our children. We need more trucks. We need five more trucks so that everyone here may be taken.  We want to express our gratitude to you and ask that you add your support."},
];

const TestimonialSlider = () => {
	const navigationPrevRef = React.useRef(null)
	const navigationNextRef = React.useRef(null)

	const [reviews,setReviews] = useState([])
    useEffect(()=>{
        client
        .fetch(
            `*[_type == "review"]{
                _id,
                name,
                message,
                image{
                  asset->{
                    _id,
                    url
                  },
                },
                slug
              }`
        )
        .then((data) => setReviews(data))
        .catch(console.error);
		console.log(reviews)
    },[])

    return (
        <>
			{reviews && reviews.length > 0 && (
				<Swiper className="testimonial-swiper"	
					speed= {1500}
					//parallax= {true}
					slidesPerView={"auto"}
					spaceBetween= {0}
					centeredSlides= {true}
					loop={true}
					autoplay= {{
					delay: 3000,
					}}
					modules={[ Autoplay ,Navigation,  Pagination]}
					onSwiper={(swiper) => {
						setTimeout(() => {
							swiper.params.navigation.prevEl = navigationPrevRef.current
							swiper.params.navigation.nextEl = navigationNextRef.current
							swiper.navigation.destroy()
							swiper.navigation.init()
							swiper.navigation.update()
						})
					}}
				>	
               
					{reviews && reviews.map((d,i)=>(
						<SwiperSlide key={i}>						
							<div className="testimonial-1">
								<div className="testimonial-text">
									<PortableText value={d.message} />
								</div>
								<div className="testimonial-details">
									<div className="testimonial-info">
										<div className="testimonial-pic">
											{d.image && d.image.asset && d.image.asset.url && (
												<img src={urlFor(d.image).url()} alt="" />
											)}
										</div>
										<div className="clearfix">
											<h5 className="testimonial-name">{d.name}</h5>
											<span className="testimonial-position">{}</span>
										</div>
									</div>
									{/*<div className="testimonial-rating">
										<ul>
											<li><i className="fa fa-star"></i></li>
											<li><i className="fa fa-star"></i></li>
											<li><i className="fa fa-star"></i></li>
											<li><i className="fa fa-star gray-light"></i></li>
											<li><i className="fa fa-star gray-light"></i></li>
										</ul>
									</div>*/}
								</div>
							</div>				
						</SwiperSlide>
						
					))}		
					{/*<div className="swiper-btn container swiper-btn-center-lr">
						<div className="test-swiper-prev btn-prev style-1" ref={navigationPrevRef}><i className="fa fa-arrow-left-long"></i></div>
						<div className="test-swiper-next btn-next style-1" ref={navigationNextRef}><i className="fa fa-arrow-right-long"></i></div>
					</div>*/}
				</Swiper>
			)}
            
        </>
    );
};


export default TestimonialSlider;