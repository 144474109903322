import React from 'react';
import {Link} from 'react-router-dom';

const FooterCommonData = ({logoImage, iconStyle}) => {
    return (
        <>
            <div className="col-xl-4 col-lg-12 col-md-12">
                <div className="widget widget_about">
                    <div className="footer-logo logo-white">
                        <Link to={"/"}><img src={logoImage} alt="" /></Link> 
                    </div>
                    <p>The Citizens’ Call - Emergency Response and Rehabilitation Initiative (ERRI) is a national non-political and not-for-profit organisation founded in Juba in April 2023 with a mission to respond  to human and natural crisis in South Sudan by providing affected people with humanitarian relief and rehabilitation.</p>
                    <div className="dz-social-icon style-1">
                        <ul>
                            <li><a target="_blank" className="fa-brands fa-facebook"  rel="noreferrer" href="https://www.facebook.com/citizenscall.erri"></a></li>
                            {" "}<li><a target="_blank" className="fa-brands fa-instagram"  rel="noreferrer" href="https://www.instagram.com/citizenscall.erri/"></a></li>
                            {" "}<li><a target="_blank" className="fa-brands fa-x-twitter"  rel="noreferrer" href=" https://x.com/citizenscall_ss?s=20"></a></li>
                            {" "}<li><a target="_blank" className="fa-brands fa-youtube"  rel="noreferrer" href="https://www.youtube.com/@citizenscallss"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-3 col-6">
                <div className="widget widget_services">
                    <h5 className="footer-title">Resources</h5>
                    <ul>
                        <li><Link to={"/news"}>News</Link></li>
                        <li><Link to={"/blog"}>Blog</Link></li>
                        <li><Link to={"/partners"}>Partners</Link></li>
                        <li><Link to={"/gallery"}>Gallery</Link></li>
                    </ul>
                </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-3 col-6">
                <div className="widget widget_services">
                    <h5 className="footer-title">Organization</h5>
                    <ul>
                        <li><Link to={"/about-us"}>About Us</Link></li>
                        <li><Link to={"/volunteer"}>Volunteers</Link></li>
                        <li><Link to={"/testimonials"}>Testimonials</Link></li>
                        <li><Link to={"/project"}>Projects</Link></li>
                        <li><Link to={"/contact-us"}>Contact Us</Link></li>
                        {/*<li><Link to={"/faq"}>Faq</Link></li>*/}
                    </ul>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="widget widget_getintuch">
                    <h5 className="footer-title">Get in Touch with Us</h5>
                    <ul>
                        <li>
                            {iconStyle   ? 
                                <i className="fas fa-map-marker-alt text-primary"></i>
                                :  
                                <i className="fas fa-map-marker-alt"></i>
                            }
                            <span> Along Pope Francis Road, Opp. American Embassy, Tongpiny, Juba</span>
                        </li>
                        <li>
                            {iconStyle   ? 
                                <i className="fa-solid fa-phone text-primary"></i>
                                :
                                <i className="fa-solid fa-phone"></i>
                            }
                            <span>Tel: +211 928 487 761 or +211 911 661 992</span>
                        </li>
                        <li>
                            {iconStyle   ? 
                                <i className="fa fa-envelope text-primary"></i> 
                                :
                                <i className="fa fa-envelope"></i> 
                            }
                            <span>info@citizenscall-erri.org</span>
                        </li>
                    </ul>
                </div>
            </div> 
        </>
    );
};

export default FooterCommonData;
