import React from 'react';
import {Link} from 'react-router-dom';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

//Images
import bgImage from './../assets/images/banner/bnr5.jpg';
import pic1 from '../assets/images/gallery/1 (1).jpg';
import pic2 from '../assets/images/gallery/1 (2).jpg';
import pic3 from '../assets/images/gallery/1 (3).jpg';
import pic4 from '../assets/images/gallery/1 (4).jpg';
import pic5 from '../assets/images/gallery/1 (5).jpg';
import pic6 from '../assets/images/gallery/1 (6).jpg';
import pic7 from '../assets/images/gallery/1 (7).jpg';
import pic8 from '../assets/images/gallery/1 (8).jpg';
import pic9 from '../assets/images/gallery/1 (9).jpg';
import pic10 from '../assets/images/gallery/1 (10).jpg';
import pic11 from '../assets/images/gallery/1 (11).jpg';
import pic12 from '../assets/images/gallery/1 (12).jpg';
import pic13 from '../assets/images/gallery/1 (13).jpg';
import pic14 from '../assets/images/gallery/1 (14).jpg';
import pic15 from '../assets/images/gallery/1 (15).jpg';
import pic16 from '../assets/images/gallery/1 (16).jpg';
import pic17 from '../assets/images/gallery/1 (17).jpg';
import pic18 from '../assets/images/gallery/1 (18).jpg';
import pic19 from '../assets/images/gallery/1 (19).jpg';
import pic20 from '../assets/images/gallery/1 (20).jpg';
import pic21 from '../assets/images/gallery/1 (21).jpg';
import pic22 from '../assets/images/gallery/1 (22).jpg';
import pic23 from '../assets/images/gallery/1 (23).jpg';
import pic24 from '../assets/images/gallery/1 (24).jpg';
import pic25 from '../assets/images/gallery/1 (25).jpg';
import pic26 from '../assets/images/gallery/1 (26).jpg';
import pic27 from '../assets/images/gallery/1 (27).jpg';
import pic28 from '../assets/images/gallery/1 (28).jpg';
import pic29 from '../assets/images/gallery/1 (29).jpg';
import pic30 from '../assets/images/gallery/1 (30).jpg';


//Component
import PageBanner from '../layouts/PageBanner';
import UpdateBlog from '../components/Home/UpdateBlog';
import Gallery from '../components/Home2/Gallery';

const imagesGallery = [
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171506/erri-website/logos/WhatsApp_Image_2023-07-12_at_113719_AM-removebg-preview-photoaidcom-cropped-1_mwptoq.png" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171494/erri-website/logos/zxicqG91_400x400_r4y2x1.jpg" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171493/erri-website/logos/Juba_City_Council_logo_d5vvuj.png" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171489/erri-website/logos/white-logo1589563336_ceobng.png" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171489/erri-website/logos/sp-white-logo_gzkixy.png" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171488/erri-website/logos/278267351_101146889244613_6676756577822866036_n_loojou.jpg" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171487/erri-website/logos/south-sudan-logo_bxmfar.svg" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171484/erri-website/logos/m-gurush-logo-1_ikceba.svg" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171483/erri-website/logos/mtn-logo_jtfflg.svg" },
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171484/erri-website/logos/311521877_500288912112449_9178651543365580328_n_smk5ph.jpg"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171483/erri-website/logos/logonew_1_k7feit.png"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171482/erri-website/logos/logo-1-1_bcqgge.png"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171480/erri-website/logos/logo_fhtzsv.svg"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171480/erri-website/logos/LOGO_j83gnf.webp"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171479/erri-website/logos/logo_1_bqfpqm.png"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171477/erri-website/logos/images_tjsanf.png"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171477/erri-website/logos/Bank_of_South_Sudan_logo_tzfezz.png"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171474/erri-website/logos/Equity_Group_Logo_rp5shx.png"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171474/erri-website/logos/images_zbr6rs.jpg"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171474/erri-website/logos/Icon-Square_nqq1ao.webp"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171471/erri-website/logos/Emblem_of_the_National_Legislative_Assembly_of_South_Sudan_slow9e.jpg"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171470/erri-website/logos/Emblem_of_the_Abyei_Area_gnv2cz.png"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171469/erri-website/logos/273846964_666901697992331_6834030223941458779_n_eiwjfb.jpg"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171468/erri-website/logos/download_gtrufm.jpg"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171465/erri-website/logos/download_1_ouww4p.jpg"},
    {image:"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1698171463/erri-website/logos/328245903_722501519278199_2446965178820805639_n_tiuctp.webp"},
];
const Partners = () => {
    const onInit = () => {
      //  console.log('lightGallery has been initialized');
    };
    return (
        <>
            <div className="page-content bg-white">
              <PageBanner maintitle="Home" pagetitle="Our Partners" background={"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1696375352/erri-website/1_30_krjovm.jpg"} />
                <div className="content-inner">
                    <div className="container">                      
                           
                        <LightGallery
                            onInit={onInit}
                            speed={500}
                            plugins={[lgThumbnail, lgZoom]}
                            elementClassNames="row mosaic-gallery"
                        >
                            {imagesGallery.map((item, index)=>(          
                                <picture data-exthumbimage={item.image} data-src={item.image}  title="Image" className="col-xl-3 col-lg-4 col-6 m-b30 d-flex" key={index}>
                                    <img  src={item.image} alt="" className='
                                    '/>
                                </picture>    
                                

                            ))}
                        </LightGallery>                        
                    </div>
                </div>
            </div>
            <hr className="container my-0" />
		
            <section className="content-inner-2">
                <div className="container">
                    <div className="section-head text-center mb-20">
                        <h6 className="sub-title">A SHORT BRIEF</h6>
                        <h2 className="title">It’s Time to Make This World</h2>
                        <p>We believe in a world without age-related diseases where everyone can live a healthier and longer life. So, join to our volunteer team!</p>
                        <Link to={"/contact-us"} className="btn btn-primary">Contact Us</Link>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Partners;