export const MenuListArray2 = [
    {
        title:"Home",
        to:"/",
    },
    {
        title:"About",
        to:"/about-us",
    },
    {
        title:"Projects",
        to: "/project"
    },
    {
        title:"Gallery",
        to:"/gallery",
    },
    {
        title:"News",
        to:"/news",
    },
    {
        title:"Team",
        to:"/volunteer",
    },
    {
        title:"Contact Us",
        to:"/contact-us",
    },
  
    /*{
        title:"Pages",
        classChange:"sub-menu-down",
        content : [
            {
                title : "About Us",
                to : "/about-us"
            },
            {
                title : "Volunteer",
                to : "#",
                className:"sub-menu",
                content : [
                    {
                        title:"Volunteer",
                        to:"/volunteer"
                    },
                    {
                        title:"Become A Volunteer",
                        to:"/become-a-volunteer"
                    },
                ],
            },
            {
                title : "Faq",
                to : "/faq"
            },
            {
                title : "Certificates",
                to : "/certificates"
            },
            {
                title : "Ask A Question",
                to : "/ask-a-question"
            },
            {
                title : "Happy Clients",
                to : "/happy-clients"
            },
            {
                title : "How It Works",
                to : "/how-it-works"
            },
            {
                title : "Mission",
                to : "/mission"
            },
            {
                title : "Terms And Condition",
                to : "/terms-and-condition"
            },
            {
                title : "Coming Soon",
                to : "/coming-soon"
            },
            {
                title : "Under Maintenance",
                to : "/under-maintenance"
            },
            {
                title : "Error 404",
                to : "/error-404"
            },
        ],
    },*/

];