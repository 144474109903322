import React from 'react';
import {Link} from 'react-router-dom';

import PageBanner from './../layouts/PageBanner';

//import shape1 from './../assets/images/pattern/shape1.png';
//import shape2 from './../assets/images/pattern/shape2.png';
//import shape3 from './../assets/images/pattern/shape3.png';
//import shape5 from './../assets/images/pattern/shape5.png';
//import shape6 from './../assets/images/pattern/shape6.png';
import NewsSlider from '../components/Home/NewsSlider';
import UpdateBlog from '../components/Home/UpdateBlog';

//images
import shape2 from '../assets/images/pattern/shape2.png';
import shape3 from '../assets/images/pattern/shape3.png';
import shape5 from '../assets/images/pattern/shape5.png';
import shape1 from '../assets/images/pattern/shape1.png';
import shape6 from '../assets/images/pattern/shape6.png';


import citizens from '../assets/images/gallery/1 (1).jpg';


const iconBlog = [
    {title:"Trusted Partner"},
    {title:"Responsibility"}
];

const wrapperBlog = [
    {icon:"flaticon-vr-glasses", title:"Quo Maxime Qui Impedit Odio Soluta"},
    {icon:"flaticon-transformation", title:"Ut Nisi Ea Vero Itaque Error Magnam"},
    {icon:"flaticon-doctor-bag", title:"Quaerat Nobis Est"},
    {icon:"flaticon-open-book", title:"Deleniti Iure Ipsa Eos Eaque Under"},
];
const bg4 = 'https://res.cloudinary.com/dqwlxo5fe/image/upload/v1696376779/erri-website/1_20_ars4e9.jpg'

const AboutUs = () => {
    return (
        <>
            <div className="page-content bg-white">
                <PageBanner maintitle="Home" pagetitle="About Us" background={"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1696375035/erri-website/1_26_lhwhrp.jpg"} />
                <section className="content-inner section-wrapper5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="section-head">
                                    <h5 className="sub-title">Who We Are</h5>
                                    {/*<h2>Serving the people of South Sudan</h2>*/}
                                    <p className="m-t20">
                                    The Citizens’ Call - Emergency Response and Rehabilitation Initiative (ERRI) is a
national non-political and not for profit Organization founded in Juba in April 2023 with a
mission to provide people affected by human and natural crises in South Sudan with
humanitarian relief and rehabilitation, health care, water and sanitation, Food security
and livelihoods, orphan and childcare, educational programs, sustainable socioeconomic programs, and to promote peace-building and social cohesion, whilst integrating our core values of respect, impartiality, quality, accountability, advocacy, and innovation in all our activities.
                                    </p>
                                    <p className='m-t20'>
                                    We have since grown to become a reputable national humanitarian and development organization in South Sudan with experienced staff, providing relief and socio-economic support services to those most affected South Sudanese. Our organization’s headquarters is in Juba; with field operation offices across the country, mainly in Renk, Joda, Budang/Roriak Rubkona County of Unity State and Aweil East County of Western Bahr El Ghazal State, respectively. We will continue to
reach wider returnees, refugees, and IDPs in various locations - for our intervention program in emergency settings.
                                    </p>
                                    <p className='m-t20'>
                                    Even as South Sudan is witnessing a rebirth in its most anticipated development,
reconstruction and peace-building, the recurrent climatic shocks, particularly floods and
dry spells and protracted civil war have left many South Sudanese and the society in
critical humanitarian need of support at all levels. Decades of political and intercommunal instability and variable climate changes have left many people, especially in rural areas, living in extreme poverty with high morbidity and mortality rates. Thus, the demand for basic necessities for survival remains extremely high.
                                    </p>
                                    <p className='m-t20'>The Emergency Response and Rehabilitation Initiative (ERRI) was founded shortly after
the establishment of “the Citizens’ Call for Emergency Evacuation and Reception of
South Sudanese Trapped in the Sudan’s war” in April 2023, to continue providing the
needed sustained onward integrated evacuation and transportation services to the
returnees up to their final destination in various states within the Country.
</p>
                                    <p className='m-t20'>
                                    The armed conflict that erupted in Sudan on 15th April 2023 left over two million South
Sudanese in Sudan were trapped and stranded. They faced many challenges including but
not limited to displacement, lack of food, lack of access to education, and shortages of
water and medical services. The majority of them became very vulnerable and susceptible to
attacks and exploitation by members of the warring parties.
It was in the above context that some motivated like-minded South Sudanese citizens
came together and unanimously agreed to initiate efforts to evacuate desperate South
Sudanese citizens who were trapped and stranded in Sudan’s conflict, to South Sudan
through border entry points in Upper Nile, Northern Bahr El Ghazal, Unity, Western
Bahr El Ghazal States respectively and Abyei Administrative Area.
                                    </p>
                                    <p className='m-t20'>
                                    The key Thematic Areas which the organization undertakes to achieve its objectives are
as follows; Protection of children and orphanages; Relief and rehabilitation; Food
security and livelihoods, Gender-Based Violence and Women empowerment, Health
and nutrition, Peace-building, and social cohesion.
The Citizens’ Call/ERRI is a lead consortium of three (3) NNGOs (CWHO, SCO-SS &
EWG) that coordinates and works with other relevant entities, including the government and the UN
agencies, CSOs, private entities or individuals to provide the needed support for the
affected people.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 m-b30">
                                <div className="dz-media">
                                    <img src={citizens} alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content-inner bg-light section-pattren1">
                    <div className="container">
                        <div className="row align-items-center project-bx right">
                            <div className="col-lg-6 col-md-12 m-b30">
                                <div className="dz-content">
                                    <h2 className="title m-b15">Our Vision</h2>
                                    <p className="m-b0">We envision a transformed society built on love, justice, and peace in which every person’s dignity is protected, especially those affected by human and natural crises, and citizens live with respect and care for the individual's integrity while having opportunities to develop them self forming a holistic perspective (spirit, soul, and body). </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 m-b30">	
                                <div className="dz-media">
                                    <div>
                                        <img src={"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1696374721/erri-website/1_24_bcmnzw.jpg"} alt="" className="app-1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content-inner">
                    <div className="container">
                        <div className="row align-items-center project-bx left m-b30">
                            <div className="col-lg-6 col-md-12">	
                                <div className="dz-media">
                                    <div>
                                        <img src={"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1696375453/erri-website/1_13_vsuo0q.jpg"} alt="" className="app-1" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="dz-content">
                                    <h2 className="title m-b15">Our Mission</h2>
                                    <p className="m-b0">To provide people affected by human and natural crises in South Sudan with emergency relief, transportation, educational programs, food security, health care, protection for children and orphans, sustainable socio-economic programs, and to promote peace-building and effective governance, whilst integrating our values of respect, impartiality, quality, accountability, advocacy, and innovation in all our work.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                         
                <section className="content-inner bg-light">
                    <div className="container">
                        <div className="row align-items-center project-bx right">
                            <div className="col-lg-6 col-md-12 m-b30">
                                <div className="dz-content">
                                    <h2 className="title m-b15">Organization Purpose</h2>
                                    <p className="m-b0">The core purpose of Emergency and Rehabilitation Initiative (ERRI) is to coordinate humanitarian basic life-saving food assistance, relief, rehabilitation, and development work to the affected population. The ERRI implements programs with the relevant UN Agencies, International NGOs, and government authorities in Resettlementthe resettlement and Reintegration of returnees and host population into community workforce plan for the sustainable peace and development of the South Sudan.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 m-b30">	
                                <div className="dz-media">
                                    <div>
                                        <img src={"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1697144714/erri-website/375670875_207759668970273_4435196891753281332_n_i10ufo.jpg"} alt="" className="app-1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content-inner-2 overlay-primary-dark about-wrapper2 bg-img-fix" style={{backgroundImage:"url("+ bg4 +")",  backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                    <div className="container">
                        <div className="about-bx2">
                            <div className="row g-0">
                                <div className="col-lg-4">
                                    <div className="dz-media">
                                        <img src={"https://res.cloudinary.com/dqwlxo5fe/image/upload/v1697144699/erri-website/362683438_179473431798897_612595206354063397_n_et8vk4.jpg"} alt="image" />
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="dz-info">
                                        <h2 className="title m-b30 m-md-b20">Remarks from the Executive Director</h2>
                                        <p className="text">The Citizens’ Call - ERRI (formerly known as Citizens’ Call for Emergency Evacuation and Reception
of South Sudanese Trapped in the Sudan’s war) was launched in late April 2023, in response to the devastating war that resulted from the collapse of the central Sudan government in April 2023, combined with severe food insecurity and diseases that claimed many lives of vulnerable individuals, especially women and children. Millions more are still at greater risk of perishing from man-made disasters and environmental catastrophes, and the terrible effects are unfortunately still being felt to date as new challenges arise every day
</p>
                                        <div className="signature">
                                            <h4 className='text-dark'>Akoc Akuei Manhiem</h4>
                                            <p className="text-dark m-t15 mb-0">Executive Director</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content-inner-2">
                    <div className="container">
                        <div className="section-head text-center">
                            <h2 className="title">Our News</h2>
                            <p>Read our latest news</p>
                        </div>
                        <NewsSlider />     
                    </div>
                </section>

                <div className="call-action style-1 content-inner-1">
                    <div className="container">
                        <UpdateBlog />
                    </div>
                </div>
            </div>
        </>
    );
};


export default AboutUs;