import {createClient} from '@sanity/client'
import imageUrlBuilder from "@sanity/image-url";

export const client = createClient({
    projectId: 'qow4keaz',
    dataset: 'erri-website',
    useCdn: true, // set to `false` to bypass the edge cache
    apiVersion: 'v2021-10-21', // use current date (YYYY-MM-DD) to target the latest API version
    // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
  })
  
const builder = imageUrlBuilder(client);

export async function getPosts() {
    const posts = await client.fetch('*[_type == "post"]')
    return posts
}

export function urlFor(source) {
	return builder.image(source);
}