import React from 'react';
import Route from './pages/Route';	

import "./assets/css/style.css";
//import "./assets/css/skin/skin-1.css";
import "swiper/css";

function App() {
  return (
    <>
		  <Route />
    </>
  );
}

export default App;
